





































































































































import VueBase from '@/VueBase'
import { Component } from 'vue-property-decorator'
import { getToken } from '@/utils/utils'
import emitter from '../taint/Emitter'

@Component({ name: 'ChangeLog' })
export default class StrategyManage extends VueBase {
  public beforeAvatarUploadLogo(file: any) {
    const isJPG = file.type === 'image/png'
    const isLt2M = file.size / 1024 / 1024 < 2

    if (!isJPG) {
      this.$message.error(this.$t('views.changeLogo.warningPng') as string)
      return false
    }
    if (!isLt2M) {
      this.$message.error(this.$t('views.changeLogo.warning2M') as string)
      return false
    }
    return isJPG && isLt2M
  }
  public beforeAvatarUploadLogoEn(file: any) {
    const isJPG = file.type === 'image/png'
    const isLt2M = file.size / 1024 / 1024 < 2

    if (!isJPG) {
      this.$message.error(this.$t('views.changeLogo.warningPng') as string)
      return false
    }
    if (!isLt2M) {
      this.$message.error(this.$t('views.changeLogo.warning2M') as string)
      return false
    }
    return isJPG && isLt2M
  }
  public beforeAvatarUploadIcon(file: any) {
    const isJPG = file.type.indexOf('ico') > -1
    const isLt2M = file.size / 1024 < 128

    if (!isJPG) {
      this.$message.error(this.$t('views.changeLogo.warningICO') as string)
      return false
    }
    if (!isLt2M) {
      this.$message.error(this.$t('views.changeLogo.warning128K') as string)
      return false
    }
    return isJPG && isLt2M
  }

  public path = process.env.VUE_APP_BASE_API
  public token = getToken()
  public logo = '/upload/assets/img/logo.png?v=' + String(Math.random())
  public logo_en = '/upload/assets/img/logo_en.png?v=' + String(Math.random())
  public favicon = '/upload/assets/img/favicon.ico?v=' + String(Math.random())

  public handleAvatarSuccess(res: any) {
    if (res.status === 201) {
      this.$message.success(res.msg)
    } else {
      this.$message.error(res.msg)
    }
    this.logo = ''
    this.logo_en = ''
    this.favicon = ''
    this.$nextTick(() => {
      emitter.emit('changelogo')
      this.logo = '/upload/assets/img/logo.png?v=' + String(Math.random())
      this.logo_en = '/upload/assets/img/logo_en.png?v=' + String(Math.random())
      this.favicon = '/upload/assets/img/favicon.ico?v=' + String(Math.random())
    })
  }
}
